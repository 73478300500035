import styled from 'styled-components'

export const WrapperForgotPassWord = styled.div`
	max-width: 100rem;
	margin: 0 auto;
	display: flex;
	padding: 4rem 0 8rem 0;
	gap: 2.4rem;
	align-items: center;
	.img-forgotpassword {
		flex: 0 0 42rem;
	}
	.joinloginFunction {
		flex: 0 0 51.6rem;
		padding: 0 2rem;
	}
	.heading-form {
		margin-bottom: 0;
	}
`
