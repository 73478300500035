export const bonusListData = [
	{
		key: 'gold_3',
		goldNum: 3,
		imgUrlMoney: `${process.env['NEXT_PUBLIC_BANNER_HOST']}/desktop/img/event/olympic/gold-a.png`,
		labelBonusGold: 'Label_Olympic_Bonus_3Gold',
		redeemImg: 'gold-a',
	},
	{
		key: 'gold_5',
		goldNum: 5,
		imgUrlMoney: `${process.env['NEXT_PUBLIC_BANNER_HOST']}/desktop/img/event/olympic/gold-b.png`,
		labelBonusGold: 'Label_Olympic_Bonus_5Gold',
		redeemImg: 'gold-b',
	},
	{
		key: 'gold_8',
		goldNum: 8,
		imgUrlMoney: `${process.env['NEXT_PUBLIC_BANNER_HOST']}/desktop/img/event/olympic/gold-c.png`,
		labelBonusGold: 'Label_Olympic_Bonus_8Gold',
		redeemImg: 'gold-c',
	},
] as const
