import dynamic from 'next/dynamic'
import { ReactNode } from 'react'
import { ISEOData } from '@nx-next-app/data-access'
import {
	useAffiliateReferralEffect,
	useChangePasswordModalEffect,
} from '@nx-next-app/hooks'
import {
	BackTop,
	GlobalModal,
	MetaData,
	Survey,
} from '@nx-next-app/components/D0001'
import { useGlobalModal } from '@nx-next-app/components/config-provider'
import LayoutHeader from '@nx-next-app/features/F0001/desktop/layout/common/header'
import LayoutFooter from '@nx-next-app/features/F0001/desktop/layout/common/footer'
import { IFooterData } from '@nx-next-app/types'

interface ILayoutProps {
	children: ReactNode
	persistSEOData: ISEOData
	footerData?: IFooterData
	showFooter?: boolean
	hasGoTopBtn?: boolean
}

const ChangePasswordModal = dynamic(
	() => import('../modal/ChangePasswordModal'),
	{ ssr: false }
)

export function Layout({
	children,
	persistSEOData,
	footerData,
	showFooter = true, // * 判斷是否顯示 footer
	hasGoTopBtn = true,
}: ILayoutProps) {
	const { globalModal, closeModal, modal } = useGlobalModal()

	useChangePasswordModalEffect(() => modal.open(<ChangePasswordModal />))

	useAffiliateReferralEffect({ expireDay: 7 })

	return (
		<>
			<MetaData persistSEOData={persistSEOData} />

			<LayoutHeader />

			{children}

			{showFooter && !!footerData && <LayoutFooter footerData={footerData} />}

			{/* 只需放在 Layout 來渲染全域彈窗 */}
			<GlobalModal {...globalModal} closeModal={closeModal} />

			{hasGoTopBtn && <BackTop />}

			<Survey />
		</>
	)
}

export default Layout
